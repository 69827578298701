import * as React from "react";
import Home from "./Home";
import Law from "./Law";
import { Routes, Route, Outlet, Link } from "react-router-dom";

export default function App() {
  return (
    <div>
      <Routes>
          <Route path="/" element={<Law />} />
          <Route path="/law-services" element={<Home />} /> 
      </Routes>
    </div>
  );
}